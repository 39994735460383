.banner {
	--tw-bg-opacity: 1;
	background-color: rgb(239 35 60 / var(--tw-bg-opacity));
}
.nutrition-coaches-page__slide-img {
	animation-duration: 25s;
	animation-duration: 25s;
}
@keyframes nutrition-coaches-page__slide-img {
	0% {
		left: 0;
	}
	15% {
		left: -400px;
	}
	30% {
		left: -800px;
	}
	50% {
		left: -1300px;
	}
	65% {
		left: -800px;
	}
	80% {
		left: -400px;
	}
	100% {
		left: 0;
	}
}
.nutrition-coaches-start-free__big-pic {
	z-index: 1;
	height: 330px;
	top: 50%;
	left: -10%;
	transform: translate(0, -50%);
	animation: nutrition-coaches-page__slide-img 25s ease infinite;
}

@keyframes MyDiv {
	0% {
		left: 0px;
		top: 0px;
	}
	100% {
		left: 100px;
		top: 0px;
	}
}
.anim {
	animation-duration: 25s;
	animation-timing-function: ease;
	animation-delay: 0s;
	animation-iteration-count: infinite;
	animation-direction: normal;
	animation-fill-mode: none;
	animation-play-state: running;
	animation-name: nutrition-coaches-page__slide-img;
	animation-timeline: auto;
	animation-range-start: normal;
}

.move-image {
	position: relative;
	/* width: 100px; */
	animation: move-left-right 25s infinite alternate;
}

@keyframes move-left-right {
	0% {
		left: 0;
	}
	15% {
		left: -400px;
	}
	30% {
		left: -800px;
	}
	50% {
		left: -1300px;
	}
	65% {
		left: -800px;
	}
	80% {
		left: -400px;
	}
	100% {
		left: 0;
	}
}

.bgs {
	width: 20rem;
	position: absolute;
	top: 36%;
	background-color: rgb(0 255 239 / 20%);
	height: 20rem;
	border-radius: 9999px;
	filter: blur(50px);
	left: 5%;
	z-index: -1;
}
.bgss {
	width: 20rem;
	position: absolute;
	top: 130%;
	background-color: rgb(0 255 239 / 20%);
	height: 20rem;
	border-radius: 9999px;
	filter: blur(50px);
	left: 73%;
	z-index: -1;
}
.bgss2 {
	width: 20rem;
	position: absolute;
	top: 200%;
	background-color: rgb(0 255 239 / 20%);
	height: 20rem;
	border-radius: 9999px;
	filter: blur(50px);
	left: 5%;
	z-index: -1;
}
.bgss3 {
	width: 20rem;
	position: absolute;
	top: 320%;
	background-color: rgb(0 255 239 / 20%);
	height: 20rem;
	border-radius: 9999px;
	filter: blur(50px);
	left: 75%;
	z-index: -1;
}
.bgst {
	width: 20rem;
	position: absolute;
	top: 620%;
	background-color: rgb(0 255 239 / 20%);
	height: 20rem;
	border-radius: 9999px;
	filter: blur(50px);
	left: 10%;
	z-index: -1;
}
.card2:hover {
	background-color: #6fecd1b5;
}
.card:hover {
	/* border: 2px solid rgb(11, 78, 89); */
	background-color: #0b4e59;

	color: white;
	/* border: 1px solid red; */
	/* border-color: #1abc9c; Change border color on hover */
	/* border-width: 4px; Increase border width on hover */
}

#response {
	/* /* margin-top: 20px; */
	/* white-space: pre-wrap; Preserve newlines and spacing */
	/* background-color: #f4f4f4; */
	padding: 10px;
	/* white-space: pre-wrap; */
	/* margin-top: 20px; */
	/* border-radius: 5px; */
}

.slider-wrapper {
	display: flex;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	/* background-color: #e0f7fa; */
}

.slide {
	display: inline-block;
	/* min-width: 150px; */
	margin: 10px;
	padding: 20px;
	text-align: center;
	/* background-color: white;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.slide img {
	width: 50px;
	height: 50px;
}

.slide p {
	max-width: 80px; /* Set your desired width */
	overflow: hidden; /* Breaks overflowing text */
	white-space: normal; /* Allows wrapping */
	text-overflow: ellipsis;
}

.skeleton-wrapper {
	width: 100%;
	height: 500px;
	border-radius: 10px;
	background-color: #e0e0e0;
	position: relative;
	overflow: hidden;
}

/* Skeleton animation */
.skeleton {
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
	background-size: 200% 100%;
	animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
	0% {
		background-position: -200% 0;
	}
	100% {
		background-position: 200% 0;
	}
}

.container {
	background-color: #e4fffa;
	border: 1px solid #13a8a6;
	padding: 20px;
	border-radius: 8px;
	width: 50%;
	margin: auto;
	margin-bottom: 20px;
}
.container h1 {
	color: #333;
}
.container ul {
	list-style-type: none;
	padding: 0;
}
.container li {
	margin-bottom: 10px;
}

.meal-plan {
	background-color: #fff;
	padding: 20px;
	margin: 0 auto;
	max-width: 600px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	h1,
	h3 {
		color: #333;
		margin-bottom: 15px;
	}
}

.meal-list,
.total-nutrients,
.recipe-links {
	list-style-type: none;
	margin-bottom: 20px;
}

.meal-item {
	padding: 15px;
	margin-bottom: 10px;
	background-color: #f9f9f9;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}

.meal-item ul {
	padding-left: 20px;
	margin-top: 10px;
}

.meal-item ul li {
	margin-bottom: 5px;
	color: #555;
}

.total-nutrients li,
.recipe-links li {
	margin-bottom: 10px;
	a {
		text-decoration: none;
		color: #007bff;
		font-weight: bold;
	}

	a:hover {
		text-decoration: underline;
	}
}
.grad {
	background: radial-gradient(
		circle,
		rgba(173, 244, 255, 0.6) 0%,
		rgba(255, 255, 255, 0.9) 70%
	);
	/* padding: 50px; */
	border-radius: 25px;
	text-align: center;
	width: 400px; /* Adjust the size as needed */
}

.pricing-table {
	width: 100%;
	background-color: rgb(19, 168, 166);
	color: white;
	border-radius: 10px;
	overflow: overlay;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.pricing-table table {
	width: 100%;
	border-collapse: collapse;
	text-align: center;
}
.pricing-table th,
.pricing-table td {
	padding: 20px;
	border: 1px solid rgba(255, 255, 255, 0.3);
}
.pricing-table th {
	background-color: rgba(19, 168, 166, 0.8);
	font-size: 1.5em;
}
.pricing-table td {
	background-color: rgba(19, 168, 166, 0.6);
}
.pricing-table tr:nth-child(even) td {
	background-color: rgba(19, 168, 166, 0.7);
}
.highlight {
	color: #ffd700;
	font-weight: bold;
	font-size: 1.2em;
}
.pricing-table .free {
	color: #28a745;
	font-weight: bold;
}
.btn-preorder {
	display: inline-block;
	padding: 10px 20px;
	margin-top: 10px;
	background-color: #ffffff;
	color: rgb(19, 168, 166);
	border: none;
	border-radius: 5px;
	text-decoration: none;
	font-weight: bold;
	transition: background-color 0.3s, color 0.3s;
	cursor: pointer;
}
.btn-preorder:hover {
	background-color: rgb(19, 168, 166);
	color: #ffffff;
}

.containermeal {
	/* width: 100%; */
	background-color: white;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: 20px;
}
.headermeal {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: rgb(19, 168, 166);
	padding: 20px;
	border-radius: 10px 10px 0 0;
	color: white;
}
.headermeal h1 {
	margin: 0;
}
.profile {
	/* display: flex; */
	justify-content: space-between;
	background-color: #e7f8f8;
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
}
.meal-card {
	background-color: white;
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: 10px;
	margin-bottom: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.meal-card h3 {
	margin-top: 0;
}
.nutrition {
	/* display: flex; */
	justify-content: space-between;
}
.nutrition span {
	display: inline-block;
	padding: 5px 10px;
	border-radius: 5px;
	margin-right: 10px;
}
.calories {
	background-color: #ffd700;
	color: #333;
}
.protein {
	background-color: #ff7675;
	color: white;
}
.carbs {
	background-color: #74b9ff;
	color: white;
}
.fat {
	background-color: #ffeaa7;
	color: #333;
}
.recipe-button {
	display: inline-block;
	padding: 10px 15px;
	background-color: rgb(19, 168, 166);
	color: white;
	border: none;
	border-radius: 5px;
	text-decoration: none;
	font-weight: bold;
	cursor: pointer;
}
.recipe-button:hover {
	background-color: #138c8a;
}

.card3 {
	/* width: 150px;
	height: 100px; */
	/* border: 1px solid #e0e0e0; */
	/* border-radius: 8px; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 10px;
}

.containerpay {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	padding: 20px;
	text-align: center;
	/* width: 400px; */
}

.containerpay h1 {
	color: #333;
	font-size: 24px;
	margin-bottom: 20px;
}

.containerpay p {
	font-size: 16px;
	color: #555;
	margin-bottom: 20px;
}

.paypal-button {
	margin-top: 20px;
}

.containerp {
	/* max-width: 1200px; */
	margin: auto;
	padding: 20px;
	background: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
header {
	background-color: rgb(19, 168, 166);
	color: white;
	padding: 20px;
	text-align: center;
}
.section {
	h1,
	h2,
	h3 {
		color: rgb(19, 168, 166);
	}
	ul {
		list-style: disc;
		padding-left: 20px;
	}
	a {
		color: rgb(19, 168, 166);
		text-decoration: none;
	}
	a:hover {
		text-decoration: underline;
	}

	margin-bottom: 40px;
}

.profile-card {
	background-color: rgb(19, 168, 166);
	width: 100%;
	border-radius: 20px;
	color: white;
	text-align: center;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.profile-card .avatar {
	background-color: #2a6076; /* Darker circle for avatar */
	width: 80px;
	height: 80px;
	border-radius: 50%;
	/* margin: 20px auto; */
}

.profile-card h2 {
	margin: 0;
	padding: 10px 0;
}

.profile-card .details {
	background-color: white;
	color: black;
	padding: 10px 25px;
	border-radius: 0 0 20px 20px;
}

.profile-card .details h3 {
	color: rgb(19, 168, 166); /* The requested RGB color */
	margin-bottom: 10px;
	text-align: left;
}

.profile-card .details p {
	margin: 5px 0;
	font-size: 14px;
	text-align: left;
}

.profile-card .details strong {
	display: inline-block;
	width: 100px;
}

/* App.css */
.chat-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	justify-content: center;
	background-color: #f5f7f7;
}

.chatbox {
	width: 600px;
	height: 500px;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	overflow-y: auto;
	padding: 20px;
}

.chat-message {
	margin-bottom: 15px;
	padding: 10px;
	border-radius: 10px;
	background-color: #e0f7fa;
}

.user-message {
	text-align: right;
	background-color: #c8e6c9;
}

.bot-message {
	text-align: left;
	background-color: #fff9c4;
}

.message-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
	font-size: 14px;
}

.sender-name {
	font-weight: bold;
}

.report-issue {
	font-size: 12px;
	color: #555;
	cursor: pointer;
}

.message-content {
	font-size: 16px;
}

.message-input {
	display: flex;
	align-items: center;
	margin-top: 20px;
	width: 100%;
}

.message-input input {
	flex: 1;
	padding: 10px;
	border-radius: 20px;
	border: 1px solid #ddd;
}

.attach-button,
.send-button {
	background-color: transparent;
	border: none;
	margin: 0 10px;
	cursor: pointer;
}

.send-button {
	color: #00796b;
}
