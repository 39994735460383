.thank-you-container {
	max-width: 600px;
	margin: 50px auto;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	text-align: center;
	h1 {
		color: #2d7a7a;
		margin-bottom: 20px;
	}

	h2 {
		color: #444;
		margin-top: 30px;
	}

	ul {
		text-align: left;
		margin: 20px 0;
		padding: 0 20px;
	}

	ul li {
		margin-bottom: 10px;
	}
	button {
		padding: 10px 20px;
		margin: 10px;
		border: none;
		border-radius: 5px;
		font-size: 16px;
		cursor: pointer;
		transition: box-shadow 0.3s ease;
	}
}
.loader {
	border: 16px solid #f3f3f3;
	border-top: 16px solid #3498db;
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
	margin: 0 auto;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.message {
	margin-top: 20px;
	font-size: 18px;
	color: #333;
}

.message span {
	display: inline-block;
	animation: blink 1.5s steps(5, start) infinite;
}

@keyframes blink {
	to {
		visibility: hidden;
	}
}

.button-container {
	margin-top: 30px;
}

.dashboard-button {
	background-color: #2d7a7a;
	color: white;
	box-shadow: 0 4px 6px rgba(45, 122, 122, 0.3);
}

.dashboard-button:hover {
	box-shadow: 0 8px 12px rgba(45, 122, 122, 0.5);
}

.home-button {
	background-color: #555;
	color: white;
	box-shadow: 0 4px 6px rgba(85, 85, 85, 0.3);
}

.home-button:hover {
	box-shadow: 0 8px 12px rgba(85, 85, 85, 0.5);
}

.container2 {
	text-align: center;
	margin-top: 100px;
}
